import React, { createContext, useState, useContext } from 'react'
import { TabsContextState } from 'types/context'

const ITabsDefaultValue = {
  shows: 'pizza',
  setShows: (state: string) => {},
  clicked: false,
  setClicked: (state: boolean) => {},
}

export const TabsContext = createContext<TabsContextState>(ITabsDefaultValue)

export const TabsProvider: React.FC = ({ children }) => {
  const [shows, setShows] = useState<string>('pizza')
  const [clicked, setClicked] = useState<boolean>(false)

  const value = {
    shows,
    setShows,
    clicked,
    setClicked,
  }

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>
}

export const useTabsContext = () => useContext(TabsContext)
